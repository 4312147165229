import { Component, OnInit } from '@angular/core';
import { DynamicFilter } from '../../core/dynamic-filter.base';
@Component({
  selector: 'dynamic-default-filter',
  templateUrl: './default-filter.component.html',
  styleUrls: ['./default-filter.component.scss'],
})
export class DefaultFilterComponent extends DynamicFilter {

}
